import { css } from '@emotion/react'
import { ComponentPropsWithoutRef, useState } from 'react'

import { toSlug } from '@/helpers'
import { dropdownArrow, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'nav'> {
  data?: readonly (Queries.DirectorySectionFragment | null)[] | null
  activeSectionId?: string
}

export const DirectoryNav = ({
  data,
  activeSectionId,
  ...props
}: Props): JSX.Element => {
  const [navOpen, setNavOpen] = useState(false)
  const styles = {
    nav: css`
      background-color: #fff;
      position: sticky;
      padding: 2em var(--margin);
      margin-top: calc(var(--row-72) - 1em);
      top: var(--nav-height-flex);
      height: calc(100vh - var(--nav-height-flex));
      box-sizing: border-box;
      overflow: scroll;
      ${mq().s} {
        height: auto;
        padding: 0 var(--margin);
        margin: 0;
        position: relative;
        display: grid;
        grid-template-columns: auto 1fr;
        justify-content: flex-start;
        align-items: center;
        position: sticky;
        top: var(--nav-height-flex);
        border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
        overflow: visible;
        z-index: 2;
      }
    `,
    listWrap: css`
      ${mq().s} {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        overflow: scroll;
        opacity: ${navOpen ? 1 : 0};
        transition: all 200ms ease-in-out;
        padding: 0.75em;
        background: #fff;
        box-shadow: 0 0 1em rgba(0, 0, 0, 0.15);
        pointer-events: ${navOpen ? 'all' : 'none'};
        max-height: calc(100vh - var(--nav-height-min) - 3.75em);
      }
    `,
    list: css`
      list-style: none;
      padding: 0;
      margin: 0;
      position: relative;
    `,
    link: css`
      display: block;
      text-transform: uppercase;
      line-height: 1.25;
      letter-spacing: 0.1em;
      font-weight: 500;
      padding: 0.375em 0;
      margin-bottom: 0.5em;
      color: ${colors.gray20};
      text-decoration: none;
      max-width: 12ch;
      min-width: min-content;
      @media (hover: hover) {
        &:hover {
          color: ${colors.red};
        }
      }
      ${mq().s} {
        max-width: 100%;
      }
    `,
    activeLink: css`
      color: ${colors.red};
      ${mq().s} {
        color: ${colors.gray20};
      }
    `,
    dropdownLabel: css`
      position: relative;
      line-height: 1;
      font-style: italic;
      color: ${colors.gray45};
      padding: 0.5em 0;
      display: none;
      z-index: 2;
      ${mq().s} {
        display: block;
      }
    `,
    dropdownButton: css`
      position: relative;
      color: ${colors.red};
      justify-self: flex-start;
      display: none;
      margin: 0;
      padding: 0.5em 0.75rem;
      z-index: 2;
      ${mq().s} {
        display: block;
      }
    `,
  }
  return (
    <nav
      css={styles.nav}
      {...props}
    >
      <span css={styles.dropdownLabel}>Jump to: </span>
      <button
        onClick={() => setNavOpen(prev => !prev)}
        css={[styles.link, styles.dropdownButton]}
      >
        Select Subject
        <div css={dropdownArrow(navOpen)} />
      </button>
      <div css={styles.listWrap}>
        <ul css={styles.list}>
          {data?.map((block, i) => (
            <li key={i}>
              <a
                href={`#${toSlug(block?.heading)}`}
                css={[
                  styles.link,
                  block?.id === activeSectionId && styles.activeLink,
                ]}
                onClick={() => setNavOpen(false)}
              >
                {block?.heading}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}
